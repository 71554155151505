(function () {
  'use strict'

  const d = document
  const lazy = d.querySelectorAll('.j__ll') || undefined
  const lzbg = d.querySelectorAll('.lzbg') || undefined
  const forEach = [].forEach


  const doImgs = () => {
    if (lazy) {
      forEach.call(lazy, e => {
        e.src = e.dataset.src || e.src
        e.classList.add('loaded')
      })
    }
    if (lzbg) {
      forEach.call(lzbg, e => {
        e.classList.add('loaded')
      })
    }
  }

  const ready = fn => {
    if (d.readyState !== 'loading') {
      fn()
    } else {
      d.addEventListener('DOMContentLoaded', fn)
    }
  }

  ready(doImgs)
}())
